.restaurant_info {
    margin-top: 40px;
    margin-bottom: 40px;

    &__title {
        margin-bottom: 0;
    }
    &__data {
        margin-top:  20px;
        &__title {
            font-weight: 700;
        }
    }
    &__additional_info {
        margin-top: 0;
        // .food_type {}
        // .food_price {}
    }
    &__email {
        & a {
            display: inline-block;
            word-break: break-all;
        }
    }
}


// .restaurant_info__description {}
// .restaurant_info__schedule {}
// .restaurant_info__address {}
// .restaurant_info__phone {}

// .restaurant_info__web {}
