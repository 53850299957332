

.bg_brand {
    background-color: $color_brand;
}

main {
    background-color: white;
    border: 1px solid #ddd;
}


ol.inline,
ol.unstyled,
ul.inline,
ul.unstyled {
    margin-left: 0;
    list-style: none
}
ol.inline>li, 
ul.inline>li {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    padding-left: 5px;
    padding-right: 5px;
}

.cabecera_responsive2 {
    ul li a {
        color: $color_brand;
        &.btn {
            color: #fff;
        }
    }

    .nav li a:focus,
    .nav li a:hover {
        text-decoration: none;
        color: #fff;
        background-color: $color_brand;
        border-radius: 3px;
    }
}



.navbar .divider-vertical {
    margin: 0 9px;
    height: 40px;
    border-left: 1px solid #f2f2f2;
    border-right: 1px solid #fff;
}

#menu_sup {
    margin-left: 0;
    
    li a {
        font-weight: 700
    }
    [id^="menu"] {
        line-height: 46px;
        margin-top: 0px;
        margin-left: 0;
        margin-bottom: 0px;
    }
}



footer.bg_brand {
    margin-top: 30px;
    padding: 15px 3% 1%;
    font-family: Verdana, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 13px;
    line-height: 16px;
    color: white;

    h4 a {
        font-style: italic;
        font-size: 18px/1.2em;
        font-family: Georgia, Helvetica, sans-serif;
        color: #FFF
    }
    
    ul li a {
        color: #fff
    }
    a,
    a:visited,
    a:focus {
        color: white;
    }
    
    #pie_udalinfo {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .footer__info--udalinfo {
        border-top: 1px dotted white;
    }
}

@media (max-width:991px) {
    #menu_sup {
        li a {
            padding: 5px;
            background-color: $color_secondary;
            border-radius: 3px
        }
        #Tiempo a {
            background: 0 0;
        }
    }
}

@media (min-width: 992px) {   
    footer .footer__info {
        padding-top: 10px;
        border-top: 1px dotted white;
    }
}