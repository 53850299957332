nav.navbar {
    padding-left: 20px;
    padding-right: 20px;
}
.navbar-toggler {
    display: block;
    color: white;
}

.btn-navbar {
    padding: 7px 10px;
    color: #fff;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1), 0 1px 0 rgba(255, 255, 255, .075);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .25);
    background-color: #093d97;
    background-image: linear-gradient(to bottom, #0f449f, #00348c);
    background-repeat: repeat-x;
    border-color: #00348c #00348c #00173f;
    border-color: rgba(0, 0, 0, .1) rgba(0, 0, 0, .1) rgba(0, 0, 0, .25);
    *background-color: #00348c;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.btn-navbar.active,
.btn-navbar.disabled,
.btn-navbar:active,
.btn-navbar:focus,
.btn-navbar:hover,
.btn-navbar[disabled] {
    color: #fff;
    background-color: #00348c;
    *background-color: #002a72;
}

.btn-navbar.active,
.btn-navbar:active {
    background-color: #002159;
}

.navbar-toggler .icon-bar {
    display: block;
    width: 18px;
    height: 2px;
    background-color: #f5f5f5;
    border-radius: 1px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, .25);
}

.navbar-toggler .icon-bar+.icon-bar {
    margin-top: 3px
}

.navbar-nav { 
    margin: 0;
    padding: 0;

    .nav-item {
        .nav-link,
        .nav-link:visited {
            padding: 15px 20px;
            color: #fff;
            font-weight: 700;
            border-radius: 4px;

        }
        .nav-link:focus,
        .nav-link:hover,
        .nav-link:active {
            text-decoration: none;
            color: $color_brand;
            background-color: #fff;
        }
        .nav-link.dropdown-toggle[aria-expanded="true"] {
            border-radius: 4px 4px 0 0;
        }
        .dropdown-menu {
            margin-top: 0;
            border: 0;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            .nav-link,
            .nav-link:visited {
                margin: 0 10px 4px;
                padding-bottom: 2px;
                width: auto;
                color: $color_brand;
                border-bottom: 2px solid transparent;
                border-radius: 0;
            }
            .nav-link:focus,
            .nav-link:hover,
            .nav-link:active {
                background-color: transparent;
                border-bottom-color: $color_brand;
            }
            .dropdown-item.active,
            .dropdown-item:active {
                color: #fff;
                text-decoration: none;
                background-color: $color_brand;
            }
        }
    }
}
.navbar-search {
    margin-left: auto;
    input#q {
        padding-left: 6px;
        padding-right: 6px;
        border-radius: 4px;
        background-color: #fff;
        border: 1px solid #ccc;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        transition: border linear .2s,box-shadow linear .2;
    }
}

@media (min-width:992px) {
    
    .navbar {
        padding-bottom: 0;
    }
    .navbar-nav { 
        margin: 0;
        padding: 0;
    
        .nav-item {
            .nav-link,
            .nav-link:visited {
                padding: 15px 20px;
                border-radius: 4px 4px 0 0;
            }
            .nav-link:focus,
            .nav-link:hover,
            .nav-link:active {
                background-color: $color_secondary;
            }
            &.active > .nav-link {
                color: $color_brand;
                background-color: white;
            }
        }
        .dropdown-menu {
            min-width: 100%;
            border-radius: 4px;
            box-shadow: 0 2px 3px 2px $color_secondary;

            .dropdown-item.active,
            .dropdown-item:active {
                background-color: transparent;
            }
        }
    }
}