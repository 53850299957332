.restaurant_menus {
    &__header {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;
        .restaurant_menus__title {
            position: relative;
            display: inline-block;
            padding: 10px 20px;
            width: 70%;
            text-align: center;
            color: white;
            background-color: $indigo;
        }
        &:before {
            position: absolute;
            display: block;
            width: calc(100% - 30px);
            height: 1px;
            content: '';
            border-bottom: 1px solid $indigo;
        }
    }
    &__carousel {
        display: flex;
        justify-content: center;

        .carousel-inner {
            display: flex;
            width: 95%;
            border-radius: 8px;
            box-shadow: 1px 1px 4px 2px darken($color_secondary, 10%);
            overflow: hidden;
        }
        .carousel-item {
            
            // .active {}
            .menu__header,
            .menu__content,
            .menu__footer {
                padding: 20px 40px;
            }
            .menu__header {
                padding-top: 40px;
                font-weight: 700;
            
                &__actions {
                    position: absolute;
                    z-index: 2;
                    top: 10px;
                    right: 20px;
                }
                &__action_link {
                    font-size: .95rem;
        
                    &,
                    &:visited,
                    &:focus {
                        color: $color_brand;
                    }
                }
            }
            .menu__footer {
                padding-top: 30px;
                padding-bottom: 20px;
                color: white;
                font-weight: 700;
                background-color: $color_brand;
            }
        }
        .carousel-control-prev,
        .carousel-control-next {
            width: 40px;
        }
        .carousel-control-prev {
            left: 7.5%;
        }        
        .carousel-control-next {
            right: 7.5%;
        }
        &__control_icon {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-size: 4rem;
            color: black;

            // &.icon-ctrl-left,
            // &.icon-ctrl-right {}
        }
    }
}

.menu__header__action_link {
    cursor: pointer;
}

@media (min-width: 992px){
    .restaurant_menus {
        &__header {
            .restaurant_menus__title {
                width: 20%;
            }
        }
        &__carousel {
            .carousel-inner {
                width: 80%;
            }
            .carousel-item {
                .menu__header,
                .menu__content,
                .menu__footer {
                    padding: 20px 60px;
                }
                .menu__header {
                    &__actions {
                        top: 20px;
                    }
                    &__title {
                        width: 80%
                    }
                }
            }
            .carousel-control-prev,
            .carousel-control-next {
                width: 60px;
            }
            .carousel-control-prev {
                left: 11.2%;
            }        
            .carousel-control-next {
                right: 11.2%;
            }
        }
    }
}