.vdp-datepicker {
    display: flex;
    flex-direction: column;
    align-items: center;

    & &__calendar {
        border-color: transparent;

        & > header {
            display: flex;
        }
        & > header,
        & .day-header {
            border-bottom: 1px dotted darken($color_secondary, 10%);
        }

        & .cell.highlighted {
            background: transparent;
            color: $red;
        }
    }
}

#closing {
    .vdp-datepicker__calendar .cell.selected {
        background: transparent;
    }
}

.vue-daterange-picker {
    width: 100%;
}
