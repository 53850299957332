.vue-new-menu {

    .general .row {
        align-items: flex-end;
    }
    .input-group {
        label {
            width: 100%;
        }
    }

    .dish_option {
        & + .dish_option {
            margin-top: 40px;
        }
        &__header_wrapper {
            position: relative;
            margin-bottom: 20px;
            padding-bottom: 20px;

            &:after {
                position: absolute;
                bottom: 0;
                left: -20px;
                display: block;
                width: calc(100% + 40px);
                height: 1px;
                content: '';
                border-bottom: 1px solid $color_secondary;
            }
        }
        &__category {
            margin-bottom: 20px;
        }
    }
    .carta__dish {
        & + .carta__dish {
            margin-top: 40px;
        }
    }
}

@media (max-width:991px){
    .vue-new-menu {
        .dish_option__header,
        .dish_option__category__dish {
            display: -ms-grid;
            display: grid;
            -ms-grid-template-columns: 1fr 17%;
            grid-template-columns:  1fr 17%;
            -ms-grid-template-rows: auto auto;
            grid-template-rows: auto auto;
        }
        .dish_option__header__option,
        .dish_option__category__dish__name {
            -ms-grid-column: 1;
            grid-column: 1;
            -ms-grid-row: 1;
            grid-row: 1;
        }
        .dish_option__header__required,
        .dish_option__category__dish__tags {
            -ms-grid-column: 1;
            grid-column: 1;
            -ms-grid-row: 2;
            grid-row: 2;
        }
        .dish_option__header__action,
        .dish_option__category__dish__action {
            -ms-grid-column: 2;
            grid-column: 2;
            -ms-grid-row: 1;
            -ms-grid-row-span: 2;
            grid-row: 1 / 3;
        }

        .dish_option__category__dish {
            & + .dish_option__category__dish {
                margin-top: 10px;
            }
            &:nth-child(even){
                position: relative;
                padding-top: 10px;

                &:before {
                    -ms-grid-column: 1;
                    grid-column: 1 / 3;
                    -ms-grid-column-span: 2;
                    -ms-grid-row: 1;
                    -ms-grid-row-span: 2;
                    grid-row: 1 / 3;
                    display: block;
                    margin-top: -10px;
                    margin-left: -10px;
                    width: calc(100% + 20px);
                    height: calc(100% + 10px);
                    content: '';
                    background-color: rgba($color_secondary, 40%);
                }
            }            
        }
    }
}