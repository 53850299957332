.dish__tags {
    display: block;
    margin-top: 4px;
    margin-bottom: 4px;
    padding-left: 20px;
    
    .dish_tag {
        position: relative;
        display: inline-block;
        margin-right: 6px;
        margin-bottom: 4px;
        padding: 0 10px 2px;
        color: white;
        border-radius: 4px;

        &--1 { //vegetariano
            background-color: $green;
        }
        &--2 { //vegano
            background-color: darken($orange, 40%);
        }
        &--3 { //gluten
            background-color: $orange;
        }
        &--4 { //crustaceos
            background-color: $cyan;
        }
        &--5 { //pescado
            background-color: $color_brand;
        }
        &:not(:first-child) {
            
        }
    }

}

// @media (min-width:768px){}