.vue_booking_form {
    width: 100%;
}
.restaurant_booking {
    margin-bottom: 40px;

    &__title {
        margin-bottom: 20px;
    }
    &__form {
        label {
            font-weight: 700;
        }
        .form-group {
            display: block;
            width: 100%;
            &:nth-child(n+2){
                margin-top: 10px;
            }
            .input-group-text {
                background-color: white;
            }
        }
        [name="reservation_time"] {
            flex: 1 0 auto;
        }
    }

}

@media (max-width:991px){
    .restaurant_booking {
        &__form {
            .vdp-datepicker {
                margin-top: 20px;
                width: 100%;
            }
        }
    }
}

@media (min-width:992px){
    .restaurant_booking {
        &__form {
            display: -ms-grid;
            display: grid;
            -ms-grid-columns: 50% 50%;
            grid-template-columns: 50% 50%;
            -ms-grid-rows: repeat(4, auto);
            grid-template-rows: repeat(4, auto);
            width: 100%;

            .form-group--name {
                -ms-grid-column: 1;
                grid-column: 1;
                -ms-grid-row: 1;
                grid-row: 1;
            }
            .form-group--phone {
                -ms-grid-column: 1;
                grid-column: 1;
                -ms-grid-row: 2;
                grid-row: 2;
            }
            .form-group--no_people {
                -ms-grid-column: 1;
                grid-column: 1;
                -ms-grid-row: 3;
                grid-row: 3;
            }
            .form-group--reservation_time {
                -ms-grid-column: 1;
                grid-column: 1;
                -ms-grid-row: 4;
                grid-row: 4;
            }
            .vdp-datepicker {
                -ms-grid-column: 2;
                grid-column: 2;
                -ms-grid-row: 1;
                -ms-grid-row-span: 4;
                grid-row: 1 / 5;
            }
        }
    }
}