.breadcrumb {
    padding: 8px 15px;
    margin: 30px 0;
    list-style: none;
    background-color: #f5f5f5;
    border-radius: 4px;
    
    & > li {
        display: inline-block;
        *display: inline;
        *zoom: 1;
        text-shadow: 0 1px 0 #fff;
        
        & > .divider {
            padding: 0 5px;
            color: #ccc;
        }
    }
    & > .active {
        color: #999;
    }
    & > .last {
        color: #999;
    }
    li {
        padding-left: 8px;
        a {
           padding-right: 8px;
           color: $color_brand;
        }
        span:after {
            content: "/";
            color: #ccc;
        }
        &.first {
            padding-left: 0;
        }
        &.last span:after {
            content: "";
        }
    }
}



