.vue-datatable-actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    &.btn-group {
        width: 100px;
    }

    @media(min-width: 992px) {
        margin: auto;
    }

    .btn {
        font-size: .9rem;
        padding: 3px 1px;
        & + .btn {
            //margin-left: 6px;
        }
    }
}
