// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$grey: #cccccc;

// GuÃ­a de estilos Donostia.eus
$color_brand: #003da5; /* se utiliza para los enlaces, tÃ­tulos y para fondos decorativos generales. */
$color_brand_pale: #004F6B;
$color_brand_light: #3E73D5;

$color_secondary: #eeeeee; /*se utiliza para fondos y detalles mÃ¡s secundarios. */

$color_text: #333333; /* se utiliza para el color del texto. */

$context_prefix: '/ingurumena/xahuketarikgabe/';
