#restaurant_slider__carousel {
    position: relative;
    height: 450px;
}
.restaurant_slider {

    &__actions {
        position: absolute;
        z-index: 2;
        top: 20px;
        right: 40px;
    }
    // &__action {}
    &__carousel {
    
        .carousel-inner {
            display: flex;
            height: 100%;
        }        
        .carousel-item {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;

            // &.active {}
        }
        &__control_icon {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            padding: 20px 5px;
            font-size: 4rem;
            background-color: rgba($color_brand, 60%);
            border-radius: 10px;

            &:before {
                height: 30px;
            }
            // &.icon-ctrl-left,
            // &.icon-ctrl-right {}
        }
        // .carousel-control-prev {}
        // .carousel-control-next {}
    }
}
