@keyframes anim-rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinner {
    display: inline-block;
    animation: anim-rotate 2s infinite linear;
}
