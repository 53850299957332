trix-toolbar div.trix-button-row {
    justify-content: flex-start;
}
trix-toolbar span.trix-button-group-spacer {
    display: none;
}

@media (max-width:767px) {
    trix-toolbar div.trix-button-row {
        flex-wrap: wrap;
    }
    trix-toolbar .trix-button-group {
        margin-top: 10px;
        margin-right: 10px;
    }
}