@import 'mixins/mixin_buttons';

@include btn-custom('btn-brand', $color_brand );
@include btn-custom('btn-grey', darken($color_secondary, 20%) );

.btn-action {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items:center;
    padding: 5px;
    width: 30px;
    height: 30px;
    color: white;
    font-size: 2rem;
    line-height: 2rem;
    border: 1px solid transparent;
    border-radius: 100%;
    transition: all .3s ease-in-out;
    overflow: hidden;

    &:visited,
    &:focus {
        outline: none;
    }
    
    &:before {
        position: absolute;
        display: block;
        width: 30px;
        height: 30px;
        &:visited,
        &:focus {
            outline: none;
        }
    }

    &--add {
        background-color: $color_brand;
        border-color: $color_brand;
        &:before {
            content: '+';
        }
        &:hover,
        &:active {
            color: $color_brand;
            background-color: white;
        }
    }
    &--copy {
        font-size: 1.3rem;
        line-height: 2.2rem;
        background-color: $cyan;
        border-color: $cyan;
        &:before {
            font-family: 'icomoon';
            content: '\e92c';
        }
        &:hover,
        &:active {
            color: $cyan;
            background-color: white;
        }
    }
    &--remove {
        font-size: 3rem;
        background-color: $red;
        border-color: $red;
        &:before {
            content: '-';
        }
        &:hover,
        &:active {
            color: $red;
            background-color: white;
        }
    }
    &--delete {
        font-size: 1.4rem;
        line-height: 2.4rem;
        background-color: $red;
        border-color: $red;
        &:before {
            font-family: 'icomoon';
            content: '\e9ac';
        }
        &:hover,
        &:active {
            color: $red;
            background-color: white;
        }
    }
    &--edit {
        font-size: 1.2rem;
        line-height: 2.2rem;
        background-color: $orange;
        border-color: $orange;
        &:before {
            font-family: "icomoon";
            content: '\e905';
        }
        &:hover,
        &:active {
            color: $orange;
            background-color: white;
        }
    }
}