.btn-switch-lang {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    color: $color_brand;
    background-color: transparent;
    border: 0;
    
    &:focus {
        outline: none;
    }
    .lang_label {
        flex: 1 0 50%;
        max-width: 50%;
        display: inline-block;
        padding: 0 4px;
    }
    .current {
        color: white;
        background-color: $color_brand;
        border: 1px solid $color_brand;
        border-radius: 2px 0 0 2px;
    }
    .lang_label + .current {
        border-radius: 0 2px 2px 0;
    }
    & ~ [lang] {
        display: none;
    }
    &[current-lang='es'] ~ [lang='es'],
    &[current-lang='eu'] ~ [lang='eu'] {
        display: block;
    }
}