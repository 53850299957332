@mixin btn-custom($name, $color, $color_text:white) {
    .#{$name},
    .#{$name}-outline {
        transition: all .3s ease-in-out;
    }
    .#{$name} {
        color: $color_text;
        background-color: $color;
        border-color: $color;
    }
    
    .#{$name}:hover,
    .#{$name}:active {
        color: $color_text;
        background-color: darken($color, 10%);
        border-color: darken($color, 12%);
    }
    
    .#{$name}:focus,
    .#{$name}.focus,
    .#{$name}-outline:focus,
    .#{$name}-outline.focus {
        box-shadow: 0 0 0 0.2rem rgba($color, 0.5);
    }
    
    .#{$name}.disabled,
    .#{$name}:disabled {
        color: $color_text;
        background-color: $color;
        border-color: $color;
    }
    
    .#{$name}:not(:disabled):not(.disabled):active,
    .#{$name}:not(:disabled):not(.disabled).active,
    .show > .btn-primary.dropdown-toggle {
        color: $color_text;
        background-color: darken($color, 10%);
        border-color: darken($color, 15%);
    }
    
    .#{$name}:not(:disabled):not(.disabled):active:focus,
    .#{$name}:not(:disabled):not(.disabled).active:focus,
    .show > .#{$name}.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba($color, 0.5);
    }

    .#{$name}-outline {
        color: $color;
        background-color: $color_text;
        border-color: $color;
    }
    .#{$name}-outline:hover,
    .#{$name}-outline:active {
        color: $color_text;
        background-color: $color;
    }
}