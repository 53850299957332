.tooltip {
    &.popover {
        $color: #f9f9f9;

        .popover-inner {
            padding: 24px;
            max-width: 300px;
            color: black;
            background: $color;
            border-radius: 5px;
            box-shadow: 0 5px 30px rgba(black, .1);
        }

        .popover-arrow {
            border-color: $color;
        }
    }
}
