@import '../variables';

.vue_component__upload--image {
    position: relative;
    &:before {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: calc(100% - 30px - 1em);
        font-size: 2rem;
        content: attr(data-label);
    }
    .upload_image_form__thumbnails {
        position: relative;
        min-height: 100px;
        background-color: rgba($color_secondary, 30%);
        border: 3px dashed darken($color_secondary, 15%);
        border-radius: 10px;

        .upload_image_form__thumbnail.uploaded {
            display: none !important;
        }
    }
}

.gallery__image {

    &__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 150px;
        max-height: 150px;
        background-color: $body-bg;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        overflow: hidden;

        // &:hover {}
    }
    &--featured {
        position: relative;
        &:after {
            position: absolute;
            top: 0;
            right: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 15px 20px 25px;
            font-family: "icomoon";
            content: '\E9D9';
            color: white;
            background-color: $color_brand;
            border-bottom-left-radius: 100%;
            box-shadow: inset 1px -1px 2px 0px white;
        }

    }
}
.gallery__image__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .gallery__image__action {
        flex: 1 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 12px;
        border-top: 0;

        &,
        &:visited,
        &:focus {
            color: white;
            background-color: darken($color_secondary, 20%);
            border: 1px solid darken($color_secondary, 25%);
        }
        &:hover,
        &:active {
            color: darken($color_secondary, 20%);
            background-color: $color_secondary;
            border: 1px solid darken($color_secondary, 25%);
        }
        &--show {
            background-color: $green;
            border: 1px solid $green;
            &:hover,
            &:active {
                color: darken($green, 20%);
                background-color: $color_secondary;
            }
        }
        &--hide {
            background-color: $orange;
            border: 1px solid $orange;
            &:hover,
            &:active {
                color: darken($orange, 20%);
                background-color: $color_secondary;
            }
        }
        &--feature {
            background-color: $color_brand;
            border: 1px solid $color_brand;
            &:hover,
            &:active {
                color: darken($color_brand, 20%);
                background-color: $color_secondary;
            }
        }
        &--delete {
            background-color: $red;
            border: 1px solid $red;
            &:hover,
            &:active {
                color: darken($red, 20%);
                background-color: $color_secondary;
            }
        }
    }
}
