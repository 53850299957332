.day--header {
    width: 105px;
}

.fc-bg {
    .fc-sun {
        background-color: rgba($color_brand_light, .3);
    }
}

.reservations_calendar__legends {
    margin-top: 20px;

    // .legends__list {}
    .legend {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        &:before {
            display: inline-block;
            margin-right: 10px;
            width: 30px;
            height: 20px;
            content: '';
            border: 1px solid #ddd;
        }
        &--today:before {
            background-color: #fcf8e3;
        }
        &--holiday:before {
            background-color: rgba($color_brand_light, .3);
        }
        &--closed:before {
            background-color:rgba(#e3342f, .3);
        }
        // &__label {}
    }
}