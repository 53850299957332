/* .vue_restaurant_list {} */

.restaurant_list__item {
    flex: 1 1 98%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 98%;
    margin: 20px 1%;
    border-radius: 4px 4px 0 0;
    box-shadow: 1px 1px 4px 2px darken($color_secondary, 10%);
    overflow: hidden;

    &__image {
        width: 100%;
        height: 200px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }
    &__info {
        flex: 1 0 auto;
        padding: 15px;
    }
    &__name,
    &__additional_info {
        color: $color_brand;
        font-weight: 700;
    }
    &__name {
        height: 3.8rem;
        font-size: 1.5rem;
        line-height: 1.8rem;
    }
    &__additional_info {
        height: 1.4rem;
        font-size: 1rem;
        line-height: 1.2rem;
    }
    /* &__price {} */
    &__description {
        margin-top: 20px;
        height: 5.8rem;
        font-size: 1rem;
        line-height: 1.4rem;
    }
}
.restaurant_list__item__link {
    &__anchor {
        display: block;
        padding: 20px;
        font-size: 1.4rem;
        font-weight: 700;
        text-align: center;
        border: 2px solid $color_brand;
        transition: all .3s ease-in-out;
    }

    &,
    &:visited,
    &:focus {
        .restaurant_list__item__link__anchor {
            color: white;
            background-color: $color_brand;
        }
    }
    &:hover,
    &:active {
        .restaurant_list__item__link__anchor {
            color: $color_brand;
            background-color: white;
        }
    }
}

@media (min-width:768px){
    .restaurant_list__item {
        flex: 1 0 48%;
        max-width: 48%;
    }
}

@media (min-width:1200px){
    .restaurant_list__item {
        flex: 1 0 31%;
        max-width: 31%;
    }
}