.restaurant_checkbox_filters {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    background-color: $color_secondary;
    border-radius: 6px;

    .checkbox_filters__toggler {
        font-size: 1.6rem;
        font-weight: 700;
        color: $color_brand;
        
        cursor: pointer;

        // &__label {}
        &__icon {
            font-size: 2.5rem;
            transform-origin: center;
            transition: all .3s ease-in-out;
        }
        &[aria-expanded="false"] .checkbox_filters__toggler__icon {
            margin-top: -1rem;
            transform: rotateX(180deg);
        }

    }
    .vue_checkbox_filters {
        margin-top: 20px;

        & > ul {
            display: flex;
            flex-wrap: wrap;
            margin: 0;

            & > li {
                flex: 1 1 98%;
                margin: 0 1% 10px;
                max-width: 98%;
            }
        }
    }
}

@media (min-width: 480px){
    .restaurant_checkbox_filters {
        .vue_checkbox_filters {
            & > ul {
                & > li {
                    flex: 1 1 48%;
                    margin: 0 1% 10px;
                    max-width: 48%;
                }
            }
        }
    }
}

@media (min-width: 768px){
    .restaurant_checkbox_filters {
        .vue_checkbox_filters {
            & > ul {
                & > li {
                    flex: 1 1 31%;
                    margin: 0 1% 10px;
                    max-width: 31%;
                }
            }
        }
    }
}

@media (min-width: 992px){
    .restaurant_checkbox_filters {
        .vue_checkbox_filters {
            & > ul {
                & > li {
                    flex: 1 1 23%;
                    margin: 0 1% 10px;
                    max-width: 23%;
                }
            }
        }
    }
}

@media (min-width: 1200px){
    .restaurant_checkbox_filters {
        .vue_checkbox_filters {
            & > ul {
                & > li {
                    flex: 1 1 18%;
                    margin: 0 1% 10px;
                    max-width: 18%;
                }
            }
        }
    }
}