.vs__deselect {
    position: relative;
    margin-right: 0;
    margin-left: 0;
    background: none;
    fill: rgba(255, 255, 255, .9);
    text-shadow: 0 1px 0 #fff;
}
.vs__selected {
    display: flex;
    align-items: center;
    margin: 4px 12px 0px 2px;
    padding: 0;
    background-color: transparent;
    border: 1px solid transparent;
}
.vs__selected_tag {
    position: relative;
    display: flex;
    padding: 0 10px 2px;
    color: white;

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% + 20px);
        height: 100%;
        content: '';
        background-color: lighten($color_brand, 30%);
        border-radius: 4px;
    }

    &__label {
        position: relative;
    }
}
.served_tag {
    &--1 { //Entre semana
        &:before {
            background-color: $green;
        }
    }
    &--2 { //Comidas
        &:before {
            background-color: darken($orange, 40%);
        }
    }
    &--3 { //Cenas
        &:before {
            background-color: $orange;
        }
    }
    &--4 { //crustaceos
        &:before {
            background-color: $cyan;
        }
    }
    &--5 { //pescado
        &:before {
            background-color: $color_brand;
        }
    }
}
.dish_tag {
    &--1 { //vegetariano
        &:before {
            background-color: $green;
        }
    }
    &--2 { //vegano
        &:before {
            background-color: darken($orange, 40%);
        }
    }
    &--3 { //gluten
        &:before {
            background-color: $orange;
        }
    }
    &--4 { //crustaceos
        &:before {
            background-color: $cyan;
        }
    }
    &--5 { //pescado
        &:before {
            background-color: $color_brand;
        }
    }
}
.turn_tag {
    &:before {
        background-color: #7FB2F0;
    }
    &--1 { //
        &:before {
            background-color: #4381C1;
        }
    }
    &--2 { //
        &:before {
            background-color: #406E8E;
        }
    }
    &--3 { //
        &:before {
            background-color: #3066BE;
        }
    }
    &--4 { //
        &:before {
            background-color: #00ABD8;
        }
    }
    &--5 { //
        &:before {
            background-color: #7ABAF2;
        }
    }
    &--6 { //
        &:before {
            background-color: #04BFBF;
        }
    }
}