.box--square {
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;

    &[disabled='disabled'] {
        &:hover {
            text-decoration: none;
        }

        opacity: .5;
        cursor: not-allowed;
    }

    h3 {
        font-size: 1.5rem;
        line-height: 2.5rem;
        font-weight: bold;
    }

    p {
        margin: 0;
        font-size: 1.2rem;
    }
}

.confirmation-msg .icon-checkbox-checked{
    font-size: 8rem;
}
.confirmation-msg .icon-cross{
    font-size: 8rem;
}
