.vue-turns__turn {
    margin-top: 30px;
}

@media (max-width: 991px){
    .vue-turns__turn {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: repeat(2, 1fr) 50px;
        grid-template-columns: repeat(2, 1fr) 50px;
        -ms-grid-rows: repeat(3, auto);
        grid-template-rows: repeat(3, auto);
        
        &__name,
        &__frequencies,
        &__start,
        &__end,
        &__actions {
            margin-top: 10px;
            padding-left: 0;
            padding-right: 0;
        }
        &__name {
            -ms-grid-column: 1;
            -ms-grid-column-span: 2;
            grid-column: 1 / 3;
            -ms-grid-row: 1;
            grid-row: 1;
        }
        &__frequencies {
            -ms-grid-column: 1;
            -ms-grid-column-span: 2;
            grid-column: 1 / 3;
            -ms-grid-row: 2;
            grid-row: 2;
        }
        &__start {
            -ms-grid-column: 1;
            grid-column: 1;
            -ms-grid-row: 3;
            grid-row: 3;
            padding-right: 5px;
        }
        &__end {
            -ms-grid-column: 2;
            grid-column: 2;
            -ms-grid-row: 3;
            grid-row: 3;
            padding-left: 5px;
        }
        &__actions {
            -ms-grid-column: 3;
            grid-column: 3;
            -ms-grid-row: 1;
            -ms-grid-row-span: 3;
            grid-row: 1 / 4;
            align-items: center;
        }
    }
}

@media (min-width: 992px){
    .vue-turns__turn {
        padding: 15px 0;
    }    
}