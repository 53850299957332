.text-brand {
    color: $color_brand;
}
.text-disabled {
    color: $grey;
}
.heading {
    font-weight: 700;
    &-0,
    &-1,
    &-2,
    &-3 {
        color: $color_brand;
        @extend .heading;
    }
    &-0 {
        font-size: 6.2rem;
        line-height: 2.8rem;
    }
    &-1 {
        font-size: 2.2rem;
        line-height: 2.8rem;
    }
    &-2 {
        font-size: 1.8rem;
        line-height: 2.2rem;
    }
    &-3 {
        font-size: 1.6rem;
        line-height: 2rem;
    }
    &-4 {
        font-size: 1.4rem;
        line-height: 1.8rem;
    }
}
