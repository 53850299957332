.form-check {
    display: flex;
    align-items: center;
    cursor: pointer;

    &-input {
        display: none;
    }
    &-label {
        position: relative;
        display: flex;
        align-items: center;
        padding-left: 30px;
        cursor: pointer;

        &:before,
        &:after {
            position: absolute;
            left: 0;
            width: 16px;
            height: 16px;
        }
        &:before {
            display: block;
            content: '';
            background-color: white;
            border: 1px solid darken($color_secondary, 30%);
        }
        &:after {
            display: none;
            justify-content: center;
            align-items: center;
            font-family: 'icomoon';
            font-size: 1.6rem;
            content: '\ea10';
            color: $color_brand;
        }
    }
    &-input:checked + .form-check-label {
        font-weight: 700;
        color: $color_brand;

        &:after {
            display: inline-flex;
        }
    }

    &-input[type='radio'] + .form-check-label {
        &:before {
            border-radius: 50%;
        }
    }
}

