.collapse__toggler {
    &__icon {
        font-size: 2.5rem;
        transform-origin: center;
        transition: all .3s ease-in-out;
    }

    &[aria-expanded="false"] .collapse__toggler__icon {
        margin-top: -1rem;
        transform: rotateX(180deg);
    }
}

.collapse_container {
    hr.full-width {
        margin: 25px -20px;
    }
}
