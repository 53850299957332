.vue-closings {
    &__wrapper {
        position: relative;

        .loader {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 9;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(255, 255, 255, .5);
        }
    }
    &__closing {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: .75rem 15px;

        &__date,
        &__turns,
        &__actions {
            flex-grow: 1;
            flex-shrink: 0;
            flex-basis: 33%;
        }
        &__date {
            max-width: 33%;
        }
        &__actions {
            display: flex;
            justify-content: flex-end;
            max-width: 80px;

            .btn-action + .btn-action {
                margin-left: 6px;
            }
        }
    }
    .vdp-datepicker {
        & &__calendar {
            .cell.day.selected {
                background: transparent;
            }
        }
    }
}

@media (max-width: 991px){
    .vue-closings {
        &__closing {
            -ms-display: grid;
            display: grid;
            -ms-grid-columns: 1fr 80px;
            grid-template-columns: 1fr 80px;
            -ms-grid-rows: auto auto;
            grid-template-rows: auto auto;

            &__date {
                -ms-grid-column: 1;
                grid-column: 1;
                -ms-grid-row: 1;
                grid-row: 1;
                max-width: 100%;
            }
            &__turns {
                -ms-grid-column: 1;
                grid-column: 1;
                -ms-grid-row: 2;
                grid-row: 2;
            }
            &__actions {
                -ms-grid-column: 2;
                grid-column: 2;
                -ms-grid-row: 1;
                -ms-grid-row-span: 2;
                grid-row: 1 / 3;
            }
        }
    }
}