.sidebar__title {
    display: block;
    margin: 0;
    padding: 3px 15px;
    font-size: 11px;
    font-weight: 700;
    line-height: 16px;
    color: #999;
    text-shadow: 0 1px 0 rgba(255,255,255,.5);
    text-transform: uppercase;
    background-color: $color_secondary;
    border-radius: 3px;

    a {
        &,
        &:visited {
            color: $color_brand_pale;

            &:focus, 
            &:hover {
                color: #000;
            }
        }
    }
}
/*
.sidebar__nav {}
.sidebar__nav__links {}
.sidebar__nav__item {}
*/
.sidebar__nav__item__link {
    display: block;

    &,
    &:visited {
        padding: 3px 15px;
        color: $color_brand;
        text-shadow: 0 1px 0 rgba(255,255,255,.5);
    }

    &:focus,
    &:hover {
        color: #000;
        text-decoration: none;
        background-color: $color_secondary;
        border-radius: 3px 3px 0 0;
    }
}




