.vue-datatable-search{
    &__label {
        margin-bottom: 0;
    }
}

@media (max-width: 991px) {
    .vue-datatable-search {
        margin-top: 20px;
    }
}