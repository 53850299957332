.vue-input-multi-lang {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 80px;
    grid-template-columns: 1fr 80px;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;

    .input-multi-lang__label {
        -ms-grid-column: 1;
        grid-column: 1;
        -ms-grid-row: 1;
        grid-row: 1;
        padding-right: 20px;
    }
    .btn-switch-lang {
        -ms-grid-column: 2;
        grid-column: 2;
        -ms-grid-row: 1;
        grid-row: 1;
    }
    .input-multi-lang__input {
        -ms-grid-column: 1;
        -ms-grid-column-span: 2;
        grid-column: 1 / 3;
        -ms-grid-row: 2;
        grid-row: 2;
    }
}
