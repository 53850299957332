* {
    box-sizing: border-box;
}

html,
body {
    margin: 0;
    padding: 0;
    font-family: Verdana,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 13px;
    line-height: 20px;
    color: #333;
    background-color: $color_secondary;
}

body {
    padding: 15px 0;
}

ul, ol, dl {
    list-style: none;
    padding-left: 0;
}
figure {
    margin-bottom: 0;
}

label {
    font-weight: 700;
}

h3 {
    line-height: 2rem;
}
.form-control,
.input-group-text,
.vs__dropdown-toggle {
    border-radius: 0;
}

.vs__dropdown-toggle {
    background-color: white;
}

.box--shadowed {
    padding: 20px 10px;
    border-radius: 10px;
    box-shadow: 1px 1px 4px 2px darken($color_secondary, 10%);
}

@media (min-width: 768px) {
    .box--shadowed {
        padding: 20px;
    }
}

.logo-dsd {

}
