.restaurant_search {
    position: relative;
    padding: 20px;
    padding-bottom: 80px;
    background-image: url('https://source.unsplash.com/1600x900/?restaurant');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    &:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: '';
        background-color: rgba(0,0,0,.2);
    }
    &__count_results {
        margin-top: 40px;
        margin-bottom: 40px;
        font-size: 1.4rem;
    }

    .dsd_logo {
        position: absolute;
        top: 10px;
        left: 0;
        display: flex;
        align-items: center;
        padding-right: 30px;
        padding-left: 10px;
        height: 60px;
        background-color: rgba(255,255,255,.8);
        border-radius: 0 30px 30px 0;
        
        & > img {
            max-height: 50px;
        }
    }

}
.restaurant_search__actions,
.restaurant_search__title,
.restaurant_search__form {
    position: relative;    
}
.restaurant_search__actions{
    margin-bottom: 10px;
}
.restaurant_search__title {
    color: white;
}
.search__form {
    display: flex;
    justify-content: center;
}
.search__input,
.search__submit {
    padding: 10px 20px;
}
.search__input {
    min-width: 60%;
    width: 60%;
}
.search__submit {
    
    &,
    &:visited {
        color: white;
        background-color: $color_brand;
        border: 1px solid $color_brand;
        transition: all .3s ease-in-out;
    }
    &:focus,
    &:hover,
    &:active {
        color: $color_brand;
        background-color: white;
    }
}
