.vue-datatable {
    &__actions,
    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__actions {
        margin-bottom: 40px;
    }
    &__footer {
        margin-top: 40px;
    }
}
.datatable {
    display: block;
    width: 100%;
}

.datatable__heading {
    display: none;
    margin-bottom: 6px;
    border-bottom: 4px solid $color_secondary;

    &__label {
        font-size: .9rem;
        line-height: 1rem;
    }
    &__icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        & > i {
            line-height: 9px;
        }
    }

    .datatable__cell--actions {
        justify-content: center;
    }
}

.datatable__body {
    position: relative;
    min-height: 50px;

    .loader {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9;
        background: rgba(255, 255, 255, 0.8);
    }
    .datatable__row {
        border-bottom: 1px solid $color_secondary;
    }
}

.datatable__row,
.datatable__row .datatable__cell {
    display: block;
}

.datatable__cell {
    padding: 6px;
}

.datatable__row .datatable__cell {
    & > span {
        word-break: break-word;
    }
}

.datatable__heading .datatable__cell {
    display: flex;
    align-items: center;
    font-weight: bold;
}

/** pagination */
.page-link {
    color: $color_brand;
}

.page-link:hover {
    color: $color_brand_pale;
}

.page-link:focus {
    box-shadow: 0 0 0 0.2rem rgba($color_brand, 0.25);
}

.page-item.active .page-link {
    background-color: $color_brand;
    border-color: $color_brand;
}


@media (max-width: 991px) {
    .vue-datatable {
        &__actions,
        &__footer {
            flex-direction: column;
        }
    }
    .datatable__row {
        padding-top: 15px;
        padding-bottom: 15px;

        &:nth-child(even) {
            background-color: #f2f2f2;
        }
        .datatable__cell {
            display: flex;
            min-width: 100%;
            &:before {
                display: block;
                width: 150px;
                font-weight: bold;
                content: attr(aria-label);
            }
        }
    }
    .vue-datatable__pagination {
        margin-top: 20px;
    }
}

@media (min-width: 992px) {
    .datatable__heading,
    .datatable__row {
        display: flex;
    }
    .datatable__cell {
        flex: 1 0 auto;
    }
}
